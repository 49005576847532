import { configureStore } from "@reduxjs/toolkit";
import categorySlice from "./features/categories/categorySlice";
import productSlice from "./features/products/productSlice";
import AllProductSlice from "./features/allProduct/AllProductSlice";
export const store = configureStore({
    reducer:{
        category:categorySlice,
        product:productSlice,  
        allproduct:AllProductSlice 
    }
})