/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from "../../component/Navbar";
import { userCategoryList } from "../../features/categories/categorySlice";
import CategoryListing from "../user/categoryListing/CategoryListing";

const Landing = () => {
  const dispatch = useDispatch();
  const { categories, isLoading } = useSelector(store => store.category);
  const { pageNoBackend } = useSelector(store => store.product);
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    dispatch(userCategoryList());
  }, [pageNoBackend]);

  useEffect(() => {
    setCategoryList(categories);
  });

  return (
    <div>
      <Navbar />
      <div className="container ">
        <h4 className="text-start mt-3">Categories</h4>
        <div className="text-end col-sm-12 col-md mt-2">
          <CategoryListing category={categoryList} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default Landing;