import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import customAxios from "../../utils/axios";

const initialState = {
  categoryFilter: false,
  userCategoryLoading:false,
  categories: [],
  allProductLoading: true
 };


export const userCategoryList = createAsyncThunk(
  "user/categoryList",
  async () => {
    try {
      const response = await customAxios.get(
        "/user/categoryList",
      );
      return response.data;
    }
    catch (e)
    {
      return (e.response.data.msg);
    }
  }
);

const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: { },
  extraReducers: {
   [userCategoryList.pending]: (state) => {
      state.userCategoryLoading = true;
      state.allProductLoading = false
    },
    [userCategoryList.fulfilled]: (state, { payload }) => {
      state.userCategoryLoading = false;
      state.allProductLoading=true
     state.categories = payload.data?.sort((a, b) => a.category_name.localeCompare(b.category_name));
    },
    [userCategoryList.rejected]: (state, { payload }) => {
      state.userCategoryLoading = false;
      state.allProductLoading= true
      toast.error(payload);
    },

  },
});

export default categorySlice.reducer;
