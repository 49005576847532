/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Navbar from "../../../component/Navbar";
import {
  clearTotalPages,
  userGetProduct,
  clearCategoryProducts,
} from "../../../features/products/productSlice";
import debounce from "lodash.debounce";
import { displayImageUrl } from "../../../utils/imageUrl";
import ReactGA from "react-ga";

const ListingProduct = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { categories } = useSelector((store) => store.category);
  const { products, pageNoBackend, totalProduct, userProductLoading } =
    useSelector((store) => store.product);
  const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState(JSON.parse(sessionStorage.getItem("pageNumber")) || 1);
  const id = useParams();
  const categoryId = id.id;
  const [productList, setProductList] = useState([]);
  let allProduct = [...new Set(productList)];
  const listRef = useRef();

  const queryParamsCategory =
    "?category_id=" +
    categoryId +
    "&product_name=" +
    search +
    "&page=" +
    pageNo;

  useEffect(() => {
    if (categories) {
      dispatch(userGetProduct(queryParamsCategory));
    }
    if(pageNo){
      sessionStorage.setItem("pageNumber",pageNo)
    }
  }, [search, pageNo, categoryId]);

  useEffect(() => {
    setProductList([...productList.concat(products)]);
  }, [products]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pageNo]);

  const nextPage = () => {
    setPageNo(pageNo + 1);
  };

  const previousPage = () => {
    setPageNo(pageNo - 1);
  };

  const gotoLastPage = () => {
    setPageNo(pageNoBackend);
  };

  const gotoFirstPage = () => {
    setPageNo(1);
  };
  const searchHandler = (e) => {
    return setProductList([]), setSearch(e.target.value), setPageNo(1);
  };

  const debounceSearchHandler = debounce((e) => {
    searchHandler(e);
  }, 500);
  const handleBack = () => {
    sessionStorage.removeItem("pageNumber")
    return (
      setProductList([]),
      clearTotalPages(),
      navigate("/"),
      dispatch(clearCategoryProducts())
    );
  };

  const categoryTit = localStorage.getItem("categoryTitle");
  useEffect(() => {
    document.title = categoryTit;
  }, [categoryTit]);

  const handleSingleProduct = (props) => {
    navigate(`/user/productdetail/${props?._id}`);
    ReactGA.event({
      category: props.product_name,
      action: "Product",
      label: "Product Name",
    });
    localStorage.setItem("productTitle", props.product_name);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.pathname]);

  return (
    <Wrapper>
      <Navbar />
      
      <div className="container">
        <button
          className="btn btn-primary text-start mt-4 mb-4"
          onClick={(e) => {
            handleBack();
          }}
        >
          <span aria-hidden="true">&laquo; Retour</span>
        </button>
        <div className="d-flex justify-content-around total-product p-2 ">
          <span>Total Product :</span>
          {userProductLoading ? (
            <div className="spinner-border product-loader" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <span>{totalProduct}</span>
          )}
        </div>
        <div className="row text-end d-flex justify-content-between">
          {/* <div className="ol-sm col-md-4 text-start"></div> */}
          <div className="input  col-md-6 mt-2 mb-2 h-4">
            <input
              type="search"
              style={{ height: "42px" }}
              // value={search}
              onChange={(e) => {
                debounceSearchHandler(e);
              }}
              className="form-control me-2"
              placeholder="Search"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
          </div>
        </div>
        {!products ? (
          <div className="col-md-12 text-secondary"> No product found </div>
        ) : (
          <div className="row mt-2" ref={listRef}>
            {!userProductLoading && allProduct && allProduct[0] ? (
              products.map((newProduct, index) => {
                return (
                  <div key={index} className="col-md-3">
                    <div
                      className="card border-1 py-2 custom-card"
                      role="button"
                      onClick={() => {
                        handleSingleProduct(newProduct);
                      }}
                    >
                      <div className="product-img">
                        <img
                          className="card-img-top rounded-2 custom-card-img"
                          src={
                            displayImageUrl +
                            `${
                              newProduct &&
                              newProduct?.main_image &&
                              newProduct.main_image[0]
                                ? newProduct?.main_image[0].filename
                                : ""
                            }`
                          }
                          alt={newProduct?.product_name}
                        />
                      </div>
                      <div className="parent-card-body">
                        <div className="card-body">
                          {newProduct?.product_name.trim().length >= 45 ? (
                            <h5 className="card-title text-dark">
                              {newProduct?.product_name.substring(0, 45)}...
                            </h5>
                          ) : (
                            <h5 className="card-title text-dark">
                              {newProduct?.product_name}
                            </h5>
                          )}
                          <div className="d-flex mt-2 flex-wrap">
                            <div className="text-sm me-1 text-secondary fs-6">
                              Tailles disponlibles:{" "}
                            </div>
                            <span className="d-flex flex-wrap text-wrapp">
                              {newProduct &&
                              newProduct.sizes &&
                              newProduct.sizes.length > 0 ? (
                                newProduct.sizes.map((size, index) => {
                                  return newProduct.sizes.length - 1 ===
                                    index ? (
                                    <p
                                      key={size}
                                      className="text-sm me-1 text-primary fw-bold"
                                    >
                                      {size}
                                    </p>
                                  ) : (
                                    <p
                                      key={size}
                                      className="text-sm me-1 text-primary fw-bold"
                                    >
                                      {size},
                                    </p>
                                  );
                                })
                              ) : (
                                <p className="text-muted text-primary">
                                  Sizes not available
                                </p>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="spinner-border text-secondary m-5" role="status">
                <span className="visually-hidden">Loading</span>
              </div>
            )}
            <div className="category-pagination ">
              <span></span>
              <button
                className="light-btn "
                onClick={() => gotoFirstPage()}
                disabled={pageNo === 1}
              >
                {"<<"}
              </button>
              <button
                className="light-btn"
                onClick={() => previousPage()}
                disabled={pageNo === 1}
              >
                Prec
              </button>
              {pageNo} of {pageNoBackend}
              <button
                className="light-btn"
                onClick={() => nextPage()}
                disabled={pageNo === pageNoBackend}
              >
                Suiv
              </button>
              <button
                className="light-btn"
                onClick={() => gotoLastPage()}
                disabled={pageNo === pageNoBackend}
              >
                {">>"}
              </button>
            </div>
          </div>
        )}{" "}
      </div>
    </Wrapper>
  );
};

export default ListingProduct;

const Wrapper = styled.div`
  .product-img {
    width: 100%;
  }
  .custom-card {
    background: #fbfbfb;
    height: 95%;
    //  width: 250px;
    margin-bottom: 17px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding-top: 0 !important;
    border-radius: 0.3rem;
    &:hover {
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 2px 9px rgb(0 0 0 / 24%);
    }
  }
  .custom-card .card-title {
    letter-spacing: 0.1px;
  }
  .product-loader {
    margin-top: 2px;
    width: 25px;
    height: 25px;
    font-size: small;
  }

  .total-product {
    width: 150px;
    background-color: #e9e8e8;
    border-radius: 5px;
  }

  .custom-card-img {
    object-fit: scale-down;
    width: 100%;
    height: 335px;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .text-wrapp {
    word-break: break-all;
  }
  .parent-card-body {
    display: flex;
    justify-content: center;
  }
  .card-body {
    max-height: 150px !important;
    overflow: auto !important;
  }

  .category-pagination {
    width: 100%;
    /* margin-top: 1rem; */
    display: flex;
    justify-content: end;
    margin-bottom: 15px;
  }
  .category-pagination * {
    margin: 0px 3px;
  }
  div::-webkit-scrollbar {
    width: 2px;
  }

  div::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  }

  div::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: gray;
    outline: 1px solid gray;
  }

  @media (max-width: 767px) {
    .category-pagination {
      display: flex;
      justify-content: space-around;
    }
  }
  @media (max-width: 767px) {
    .card-body {
      max-width: 260px;
    }
  }
`;
