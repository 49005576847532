import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import customAxios from "../../utils/axios";
const initialState = {
    allProduct: [],
    userAllProductLoading: false,
    totalProduct: "",
    pageNoBackend: "",
    catReduxPage: "",
}
export const getAllProduct = createAsyncThunk(
    "user/allProduct",
    async (pageNo) => {
      try {
        const response = await customAxios.get(
          `/user/getAllProductWithoutPrice?${pageNo}`
        );
        return response.data;
      } catch (e) {
        return e.response.data.msg;
      }
    }
  );

  const allProductSlice = createSlice({
    name:"allProduct",
    initialState,
    reducers: {
        clearTotalPages: (state) => {
          state.catReduxPage = -1;
          state.pageNoBackend = -1;
        },
        clearCategoryProducts: (state) => {
          state.allProduct = [];
        },
      },
    extraReducers:{
        [getAllProduct.pending]: (state) => {
            state.userAllProductLoading = true;
          },
          [getAllProduct.fulfilled]: (state, { payload }) => {
            state.userAllProductLoading = false;
            state.allProduct = payload?.data;
            state.pageNoBackend = payload?.totalPages;
            state.totalProduct = payload?.productCount;
          },
          [getAllProduct.rejected]: (state) => {
            state.userAllProductLoading = false;
          },
    }
  })
  export const { clearTotalPages, clearCategoryProducts } =
  allProductSlice.actions;
  export default allProductSlice.reducer