import React from "react";
import Wrapper from "../assets/wrappers/Navbar";
import logo from "../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

function Navbar() {
const navigate = useNavigate()
const handleBackToHomePage = ()=>{
  navigate('/')
  sessionStorage.removeItem("pageNumber")
}
  return (
    <Wrapper>
      <div className="nav-center">
        <div className="user-logo-text">
          <div className="client-logo" onClick={()=>handleBackToHomePage()}>
            {" "}
            <img className="navbar-logo-image" src={logo} alt="jobify" />{" "}
            <h3>ChapShop</h3>{" "}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Navbar;
