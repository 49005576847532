import { Route, Routes } from "react-router-dom";
import Landing from "./pages/home/Index";
import ProductDetails from "./pages/user/ProductDetailPage/Detail";
import Error from "./pages/Error";
import ListingProduct from "./pages/user/listingPage/ListingProduct";
import ReactGA from "react-ga";
import ListingAllProduct from "./pages/user/allProductPage/ListingAllProduct";
import AllProductDetails from "./pages/user/ProductDetailPage/AllProductDetails";


const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <Routes>
      <Route exact path="*" element={<Error />}></Route>
      <Route path="/" element={<Landing />} />
      <Route
        exact
        path="/user/productdetail/:id"
        element={<ProductDetails />}
      ></Route>
       <Route
        exact
        path="/user/allproductdetail/:id"
        element={<AllProductDetails />}
      ></Route>
      <Route
        path="/user/categorylist/products/:id"
        element={<ListingProduct />}
      />
      <Route path="/user/allProduct" element={<ListingAllProduct/>} />
    </Routes>
  );
}

export default App;
