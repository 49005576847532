import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customAxios from "../../utils/axios";

const initialState = {
  singleProductLoading: false,
  userProductLoading: false,
  userSingleProductLoading: false,
  singleProductData: [],
  products: [],
  totalProduct: "",
  singleProduct: {},
  pageNoBackend: "",
  catReduxPage: "",

};

export const userGetProduct = createAsyncThunk(
  "user/productList",
  async (data) => {
    try {
      const response = await customAxios.get(
        `/user/productListWithoutPrice/${data}`
      );
      return response.data;
    } catch (e) {
      return e.response.data.msg;
    }
  }
);

export const userSingleProduct = createAsyncThunk(
  "user/getProduct",
  async (data, thunkAPI) => {
    try {
      const response = await customAxios.get(
        `/user/getProductWithoutPrice/${data}`
      );
      return response.data;
    } catch (e) {
      return e.response.data.message;
    }
  }
);


const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    clearTotalPages: (state) => {
      state.catReduxPage = -1;
      state.pageNoBackend = -1;
    },
    clearCategoryProducts: (state) => {
      state.products = [];
    },
  },
  extraReducers: {
    [userGetProduct.pending]: (state) => {
      state.userProductLoading = true;
    },
    [userGetProduct.fulfilled]: (state, { payload }) => {
      state.userProductLoading = false;
      state.products = payload?.data;
      state.pageNoBackend = payload?.totalPages;
      state.catReduxPage = payload?.totalPages;
      state.singleProduct = {};
      state.totalProduct = payload?.productCount;
    },
    [userGetProduct.rejected]: (state, { payload }) => {
      state.userProductLoading = false;
    },
    [userSingleProduct.pending]: (state) => {
      state.userSingleProductLoading = true;
    },
    [userSingleProduct.fulfilled]: (state, { payload }) => {
      state.userSingleProductLoading = false;
      state.singleProduct = payload.data;
      state.products = [];
    },
    [userSingleProduct.rejected]: (state, { payload }) => {
      state.userSingleProductLoading = false;
    },
 
  },
});

export const { clearTotalPages, clearProduct, clearCategoryProducts } =
  productSlice.actions;

export default productSlice.reducer;
