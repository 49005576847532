import styled from 'styled-components'

const Wrapper = styled.nav`
  height: var(--nav-height);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 0px 0px rgba(0, 0, 0, 0.1);
  z-index:100;

  .nav-center {
    display: flex;
    width: 90vw;
    align-items: center;
    justify-content: space-between;
  }


.user-logo-text{
  margin: auto;
  /* width: 50%; */
  /* border: 3px solid green; */
  padding: 10px;
}


.client-logo{
  display:flex;
  justify-content: space-around;
  align-items:center;
  width: 220px;
}
.navbar-logo-image{
  width:50px;
  height:50px;
}


  @media (min-width: 992px) {
    .nav-center {
      width: 90%;
    }
    .logo {
      display: none;
    }
    .logo-text {
      display: block;
    }
  }
`
export default Wrapper
